import styled from 'styled-components';

export const Wrapper = styled.div`
  background: black;
  width: 100%;
`;

export const Overlay = styled.div`
background: black;

  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: none;
  transition: 0.4s;

  ${({ sidebar }) =>
    sidebar &&
    `
			display: block;
			z-index: 4;	
	`}
`;
